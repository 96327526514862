import { createSlice } from '@reduxjs/toolkit'

export interface ReferralState {
  skipCongratsReferral: boolean
}

const initialState: ReferralState = {
  skipCongratsReferral: false,
}

export const ReferralSlice = createSlice({
  name: 'referralNonPersist',
  initialState,
  reducers: {
    SetSkipCongratsReferral: (state, action) => {
      state.skipCongratsReferral = action.payload
    },
  },
})

export default ReferralSlice.reducer

export const { SetSkipCongratsReferral } = ReferralSlice.actions
