import { createSlice } from '@reduxjs/toolkit'

export interface OffersState {
  skipCongratsOffers: boolean
}

const initialState: OffersState = {
  skipCongratsOffers: false,
}

export const OffersSlice = createSlice({
  name: 'OffersNonPersist',
  initialState,
  reducers: {
    SetSkipCongratsOffers: (state, action) => {
      state.skipCongratsOffers = action.payload
    },
  },
})

export default OffersSlice.reducer

export const { SetSkipCongratsOffers } = OffersSlice.actions
