import { useEffect, useState } from 'react'
import { Button, Dialog, DialogTitle } from '@mui/material'
import axios from 'axios'
import { format } from 'date-fns'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { SetSelectedSlot, SetSlotBookedAlready } from 'redux/persist'
import { useAppDispatch, useAppSelector } from 'redux/store'
import styles from './ConfirmAppointment.module.scss'

const ConfirmAppointment = () => {
  const styless: any = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //   position: 'absolute' as 'absolute',
    //   top: '44%',
    //   left: '47.5%',
    //   transform: 'translate(-50%, -50%)',
    width: 300,
    height: 170,
    overflow: 'hidden',
    backgroundColor: '#050624',
    color: 'white',
    borderRadius: '8px',
    p: 4,
  }
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const appointmentTime = useAppSelector((state) => state.selectedSlot.selectedSlot)
  const slotBookedAlready = useAppSelector((state) => state.slotBooked.slotBookedAlready)
  const [error, setError] = useState(false)
  const paymentDone = useAppSelector((state) => state.payment.paymentDone)

  const schedulePhysicalVisit = async () => {
    if (appointmentTime?.scheduleObject) {
      const response: any = await axios
        .post(AppConstants.API_URL + '/visit/chiropractor/appointment', appointmentTime.scheduleObject, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })

      if (response?.data?.code === 'success') {
        navigate('/welcome')
      } else {
        if (response?.data?.code === 'slot_booked_already') {
          setError(true)
          dispatch(SetSlotBookedAlready(false))
          dispatch(SetSelectedSlot(null))
        } else {
        }

        return false
      }
    } else {
      return false
    }
  }

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'confirm-visit',
      page_location: '/confirm-visit',
    })
  }, [])

  return (
    <>
      <div className={styles.container}>
        <Header
          headerText="Confirm Visit"
          showBackButton={true}
          onBack={() => {
            navigate('/physical-visit')
          }}
        ></Header>
        <div className={styles.locationContainer}>
          <div className={styles.appointmentTime}>
            {appointmentTime
              ? format(new Date(appointmentTime?.scheduleObject.appointmentTime), "EEE, MMM dd, yyyy 'at' h:mm a")
              : ''}
          </div>
          <div className={styles.locationDetails}>
            <div>
              <div className={styles.locationText}>KIRO Studio/1 in NoHo </div>
              <div className={styles.locationText}>302 Mott Street </div>
              <div className={styles.locationText}>New York, NY 10012</div>
            </div>
            <div className={styles.mapImage}>
              <img
                style={{ width: '93px', height: '73px', cursor: 'pointer' }}
                src={'/images/mapImage.png'}
                alt="map"
                onClick={() => {
                  window.open(
                    'https://www.google.com/maps/place/KIRO/@40.7248864,-73.996436,16z/data=!3m1!4b1!4m6!3m5!1s0x89c259f7e55d3a41:0x5a2269694f5fbb88!8m2!3d40.7248864!4d-73.9938611!16s%2Fg%2F11vyhmf57s?entry=ttu',
                    '_blank',
                  )
                  //   window.location.href =
                  //     'https://www.google.com/maps/place/302+Mott+St,+New+York,+NY+10012,+USA/@40.7248592,-73.9940347'
                }}
              />
            </div>
          </div>

          {error && (
            <Dialog
              onClose={() => {
                // setError(false)
              }}
              open={error}
              PaperProps={{ style: styless }}
              className={styles.modalPopUp}
            >
              <DialogTitle style={{ fontSize: '14px', marginTop: '0rem' }}>
                We’re sorry, the slot you picked has been booked already. Please select another slot.
              </DialogTitle>
              <Button
                color="primary"
                style={{
                  width: 'fit-content',
                  marginBottom: '1rem',
                  height: '2rem',
                  padding: '0px 8px 0px 8px',
                  fontSize: '12px',
                }}
                onClick={() => {
                  setError(false)
                  navigate('/physical-visit')
                }}
              >
                Reschedule
              </Button>
            </Dialog>
          )}
        </div>
        <Footer
          buttonText={paymentDone ? 'Confirm' : 'Confirm and Pay'}
          isLoading={false}
          trackerIndex={3}
          shouldShowFour={true}
          isDisabled={false}
          buttonType={'button'}
          onButtonClick={() => {
            if (!paymentDone && appointmentTime?.scheduleObject) {
              navigate('/payment')
            } else {
              schedulePhysicalVisit()
            }
          }}
        ></Footer>
      </div>
    </>
  )
}

export default ConfirmAppointment
