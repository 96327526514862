import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { useAppSelector } from 'redux/store'
import styles from './BlandWelcome.module.scss'

const BlandWelcome = () => {
  const navigate = useNavigate()
  const name = useAppSelector((state) => state.regular.firstname)

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Bland | Welcome',
      page_location: '/bland/welcome',
    })
  }, [])

  return (
    <>
      <div className={styles.container}>
        {/* <Header
          headerText={`Welcome, ${name}`}
          showBackButton={false}
          // onBack={() => {
          //   navigate('/add-photo')
          // }}
        ></Header> */}
        <div className={styles.header}>
          <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', left: '18px', top: '20px' }}>
            <span>{'Welcome to KIRO,'}</span>
            <span style={{ textTransform: 'capitalize' }}>{`${name}!`}</span>
          </div>
          <img src={'/logo2.png'} alt="logo" className={styles.logo} />
        </div>

        <div className={styles.welcomeMessage}>
          Rachel from KIRO is going to call you right away. Please check your phone.
        </div>

        <Footer
          buttonText={'Done'}
          isLoading={false}
          trackerIndex={10}
          shouldShowFive={true}
          isDisabled={false}
          buttonType={'button'}
          onButtonClick={() => {
            navigate('/download')
          }}
        ></Footer>
      </div>
    </>
  )
}

export default BlandWelcome
