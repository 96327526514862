import React, { useEffect, useMemo, useState } from 'react'
import { CircularProgress, Grid, Typography } from '@mui/material'
import axios from 'axios'
import ReactGA from 'react-ga4'
import { useNavigate, useParams } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { IPlan, SetAvailablePlansBooking, SetPurchasedPlanBooking, SetSelectedPlanBooking } from 'redux/booking'
import { SetAvailablePlans } from 'redux/persist'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { StatusEnum } from 'redux/types'
import styles from './ChoosePlan.module.scss'

const ChoosePlanBooking = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const [error, setError] = useState(false)
  const [disableButton, setDisableButton] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const plans = useAppSelector((state) => state.booking.availablePlansBooking)
  const [fetchPlanStatus, setFetchPlanStatus] = useState<StatusEnum>(StatusEnum.Idle)
  const [userInfo, setUserInfo]: any = useState(null)
  const selectedPlanInfo = useAppSelector((state) => state.booking.selectedPlanBooking)
  const referralCode = useAppSelector((state) => state.booking.referralCodeBooking)

  // plans order
  const planList = useMemo(() => {
    if (plans) {
      const membershipPlan = plans?.find((plan) => plan.type === 'subscription')
      const otherPlans = plans?.filter((plan) => plan.type !== 'subscription')
      return membershipPlan ? [membershipPlan, ...otherPlans] : otherPlans
    }
  }, [plans])

  //choose plan

  const onContinue = async () => {
    console.log('on continue')
    navigate(`/booking/${id}/physical-visit`)
  }

  async function getPlans() {
    setFetchPlanStatus(StatusEnum.Pending)
    try {
      const response: any = await axios.get(`${AppConstants.API_URL}/payment-plan`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      if (response && response?.data && response?.data?.data) {
        dispatch(SetAvailablePlansBooking(response?.data?.data))

        setFetchPlanStatus(StatusEnum.Success)
      } else if (response?.data?.statusCode === 401) {
        setFetchPlanStatus(StatusEnum.Failed)
        console.log('error')
      }
    } catch (error) {
      setFetchPlanStatus(StatusEnum.Failed)
      console.error(error)
    }
  }

  useEffect(() => {
    getPlans()
    // getProfile()
    if (selectedPlanInfo) {
      setDisableButton(false)
    }
  }, [])

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Choose Plan',
      page_location: '/choose-plan',
    })
  }, [])

  return (
    <>
      <div className={styles.container}>
        <Header
          headerText="Choose a Plan"
          showBackButton={false}
          // onBack={() => {
          //   navigate('/add-photo')
          // }}
        ></Header>
        <div className={styles.planDetails}>
          {fetchPlanStatus === StatusEnum.Pending && (
            <div className={styles.loadingWrapper}>
              <CircularProgress style={{ color: '#050624' }} size={30}></CircularProgress>
            </div>
          )}
          {fetchPlanStatus === StatusEnum.Success && (
            <div>
              <form>
                <Grid container spacing={2}>
                  {planList?.map((plan: IPlan, index: number) => {
                    let membershipDescription: any = null
                    if (plan.type === 'subscription') {
                      let updatedDescriptions: any = [
                        `${plan.maxCount ?? 0} Adjustments per Month`,
                        'Nervous System Scan',
                        'Renews Automatically',
                        'Cancel Anytime',
                      ]
                      membershipDescription = updatedDescriptions
                    }
                    return (
                      <Grid item xs={12} key={index}>
                        <div
                          onClick={(e) => {
                            dispatch(SetSelectedPlanBooking(plan))
                            setDisableButton(false)
                          }}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '10px 15px',
                            border: selectedPlanInfo?.id === plan.id ? '2px solid #007F7C' : '2px solid #2A2D56',
                            borderRadius: '6px',
                            backgroundColor: '#2A2D56',
                            color: '#E5E7EB',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div style={{ textTransform: 'capitalize' }}>
                              {plan?.type === 'subscription' && (
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                  <img
                                    src={'/images/logoV3.svg'}
                                    style={{
                                      width: '22px',
                                      height: '22px',
                                    }}
                                    alt="logo"
                                  ></img>
                                  <span>Membership</span>
                                </div>
                              )}
                              {/* {plan?.name === 'Membership (Popular)' && <span>Membership</span>} */}
                              {plan?.type === 'on-demand' && <span>Single Adjustment</span>}
                            </div>
                            <span style={{ fontWeight: 600 }}>
                              $ {plan.type === 'subscription' ? `${plan.amount / 100}/mo.` : plan.amount / 100}
                            </span>
                          </div>
                          {membershipDescription !== null && (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                              }}
                            >
                              {membershipDescription.map((list: any) => {
                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      gap: '12px',
                                      alignItems: 'center',
                                      marginTop: '8px',
                                      marginLeft: '6px',
                                    }}
                                  >
                                    <img
                                      src={'/images/bullets.svg'}
                                      style={{ width: '8px', height: '8px' }}
                                      alt="tick"
                                    ></img>
                                    <div style={{ fontSize: '14px', fontWeight: 100 }}>{list}</div>
                                  </div>
                                )
                              })}
                              {/* <p>{plan?.description}</p> */}
                            </div>
                          )}
                        </div>
                      </Grid>
                    )
                  })}
                </Grid>
              </form>
              {/* <div
                style={{
                  color: '#E5E7EB',
                  fontSize: '16px',
                  marginTop: '30px',
                  marginLeft: '1px',
                }}
              >
                <b>All Plans Include a Complimentary Screening</b>
                <br />
                <div style={{ marginTop: '1rem', lineHeight: '1.5rem' }}>
                  Following your screening, if you choose to opt-out, we'll refund you 100%.
                </div>
              </div> */}
            </div>
          )}
        </div>

        <div className={styles.btnWrapper}>
          <Grid item xs={12}>
            {error && (
              <div className={styles.errorContainer}>
                <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>Please choose a plan you want ?</Typography>
                <div
                  style={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingRight: '12px',
                  }}
                >
                  <img
                    onClick={() => {
                      setError(false)
                    }}
                    style={{ cursor: 'pointer' }}
                    src={'/images/wrong.svg'}
                  ></img>
                </div>
              </div>
            )}
          </Grid>
        </div>
        <Footer
          buttonText={'Continue'}
          isLoading={loadingButton}
          trackerIndex={1}
          // shouldShowFive={referralCode === null ? true : false}
          shouldShowFour={true}
          isDisabled={disableButton}
          buttonType={'submit'}
          onButtonClick={() => {
            disableButton ? undefined : onContinue()
          }}
        ></Footer>
      </div>
    </>
  )
}

export default ChoosePlanBooking
