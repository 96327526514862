import React, { useState, useEffect, useMemo } from 'react'
import { Button, CircularProgress, DialogTitle, Grid, Modal, Typography, useMediaQuery } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import axios from 'axios'
import format from 'date-fns/format'
import ReactGA from 'react-ga4'
import { useNavigate, useParams } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
import HorizontalCalendar from 'components/shared/HorizontalCalendar'
import { SetSelectedSlotBooking, SetSlotBookedAlreadyBooking } from 'redux/booking'
import { useAppDispatch, useAppSelector } from 'redux/store'
import styles from './Onboard.module.scss'

export interface ITime {
  index: number
  value: string
}
interface IScheduleInitialValues {
  appointmentTime: string
  timezone: string
}
const scheduleInitialValues: IScheduleInitialValues = {
  appointmentTime: '',
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
}

const styless: any = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  //   position: 'absolute' as 'absolute',
  //   top: '44%',
  //   left: '47.5%',
  //   transform: 'translate(-50%, -50%)',
  width: 300,
  height: 170,
  overflow: 'hidden',
  backgroundColor: '#050624',
  color: 'white',
  borderRadius: '8px',
  p: 4,
}

const PhysicalVisitBooking = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { id } = useParams()

  const selectedSlot = useAppSelector((state) => state.booking.selectedSlotBooking)
  const [disableButton, setDisableButton] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const [loadingContanier, setLoadingContainer] = useState(true)
  const [visitAvailabilities, setVisitAvailabilities] = useState()
  const [selectedDate, setSelectedDate] = useState(selectedSlot ? new Date(selectedSlot?.date) : new Date())
  const [selectedTime, setSelectedTime] = useState<any | null>(selectedSlot ? selectedSlot?.value : null)
  const [selectedIndex, setSelectedIndex] = useState<any | null>(selectedSlot ? selectedSlot?.index : null)
  const [scheduleData, setscheduleData] = useState({
    appointmentTime: selectedSlot?.scheduleObject?.appointmentTime || '',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  })
  const [deselect, setDeSelect] = useState(false)
  const [apiFailed, setApiFailed] = useState(false)
  const [showDialogue, setShowDialogue] = useState(true)
  const paymentDone = useAppSelector((state) => state.booking.paymentDoneBooking)

  const slotBookedAlready = useAppSelector((state) => state.booking.slotBookedAlreadyBooking)
  const referralCode = useAppSelector((state) => state.booking.referralCodeBooking)

  const availabileSlots: any = visitAvailabilities

  const timeDetails: any = useMemo(() => {
    return (
      availabileSlots?.map((timeString: any) => {
        const formattedTime = format(new Date(`2000-01-01T${timeString}`), 'HH:mm')
        return formattedTime
      }) || []
    )
  }, [availabileSlots])

  const formattedTimes: string[] =
    timeDetails?.map((timeString: any) => {
      const convertedTime = format(new Date(`2000-01-01T${timeString}`), 'h:mm aa')
      return convertedTime
    }) || []

  const handleSelect = async (value: any, index: any) => {
    setSelectedTime(value)
    setSelectedIndex(index)
    setDisableButton(false)

    let scheduleTime = timeDetails[index]

    let selectedAppointmentTime = String(
      selectedDate.getFullYear() +
        '-' +
        String(selectedDate.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(selectedDate.getDate()).padStart(2, '0') +
        ' ' +
        scheduleTime,
    )
    setscheduleData({ ...scheduleData, appointmentTime: selectedAppointmentTime })
  }

  const formattedDate =
    selectedDate.getFullYear() +
    '-' +
    String(selectedDate.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(selectedDate.getDate()).padStart(2, '0') +
    ' ' +
    '00:00:00'

  useEffect(() => {
    getDoctorAvailability(formattedDate)
  }, [])

  useEffect(() => {
    if (selectedSlot) {
      let isSlotSelected = formattedTimes?.includes(
        format(new Date(selectedSlot?.scheduleObject.appointmentTime), 'h:mm aa'),
      )

      if (isSlotSelected && !deselect) {
        setDisableButton(false)
      }
    }
  }, [formattedTimes])

  const handleSelectedDate = () => {
    dispatch(SetSelectedSlotBooking(null))
    setSelectedIndex(null)
    setSelectedTime(null)
    setscheduleData({ ...scheduleData, appointmentTime: '' })
    setDisableButton(true)
    setSelectedDate((prevSelectedDate: any) => {
      const formattedDate =
        prevSelectedDate.getFullYear() +
        '-' +
        String(prevSelectedDate.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(prevSelectedDate.getDate()).padStart(2, '0') +
        ' ' +
        '00:00:00'
      getDoctorAvailability(formattedDate)
      return prevSelectedDate
    })
  }

  async function getDoctorAvailability(formattedDate: any) {
    setLoadingContainer(true)
    setApiFailed(false)
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    try {
      let response = await axios
        .get(AppConstants.API_URL + `/visit/clinic/availability?date=${formattedDate}&timezone=${timeZone}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        })
        .then((res) => {
          setLoadingContainer(false)
          if (res?.data?.data.length === 0) {
            setVisitAvailabilities(res?.data?.data)
          } else if (res?.data?.data?.length > 0) {
            let filteredData: any = []
            let seenHours: any = {}
            res?.data?.data.forEach((time: any) => {
              const hour = time.split(':')[0]
              if (!seenHours[hour]) {
                filteredData.push(time)
                seenHours[hour] = true
              }
            })
            setVisitAvailabilities(filteredData)
          }
        })
        .catch((err) => {
          if (err) {
            setApiFailed(true)
            setLoadingContainer(false)
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'physical-visit',
      page_location: '/physical-visit',
    })
  }, [])

  return (
    <>
      <div className={styles.container}>
        <Header
          headerText="Schedule Visit"
          showBackButton={!referralCode ? true : false}
          onBack={() => {
            navigate(`/booking/${id}/choose-plan`)
          }}
        ></Header>
        <div className={`${styles.scheduleDetails}`}>
          <div>
            <HorizontalCalendar
              onClick={() => {
                handleSelectedDate()
              }}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />

            <div className={styles.toDayDate}>{format(new Date(selectedDate), 'EEEE, MMMM d')}</div>
            <div className={styles.slotContainer}>
              {loadingContanier === true && (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <CircularProgress style={{ color: '#007f7c' }} size={30}></CircularProgress>
                </div>
              )}
              {formattedTimes.length !== 0 && loadingContanier === false && (
                <Grid columns={1} container spacing={2}>
                  {formattedTimes?.map((value: any, index) => {
                    return (
                      <Grid
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        item
                        xs={6}
                        sm={6}
                        key={index}
                      >
                        <Button
                          className={styles.slotButton}
                          onClick={() => {
                            handleSelect(value, index)
                            if (selectedTime === value) {
                              setSelectedTime(null)
                              setSelectedIndex(null)
                              setDeSelect(true)
                              setscheduleData({
                                appointmentTime: '',
                                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                              })
                              setDisableButton(true)
                            }
                          }}
                          sx={{
                            backgroundColor: selectedTime === value ? '#F5F5F5' : '#2A2D56',
                            border: selectedTime === value ? ' 1px solid #007F7C' : '1px solid #2A2D56',
                            // height: '56px',
                            // width: '160px',
                            // '@media (min-width: 600px)': {
                            //   width: '140px',
                            //   height: '46px',
                            //   fontSize: '14px',
                            // },
                            // '@media (max-width: 375px)': {
                            //   width: '150px',
                            //   height: '46px',
                            //   fontSize: '14px',
                            // },
                            // '@media (max-width: 350px)': {
                            //   width: '138px',
                            //   height: '46px',
                            //   fontSize: '14px',
                            // },
                            // '@media (max-width: 330px)': {
                            //   width: '130px',
                            //   height: '46px',
                            //   fontSize: '14px',
                            // },
                            // '@media (max-width: 310px)': {
                            //   width: '120px',
                            //   height: '46px',
                            //   fontSize: '14px',
                            // },
                            // '@media (max-width: 300px)': {
                            //   width: '110px',
                            //   height: '46px',
                            //   fontSize: '14px',
                            // },
                            color: selectedTime === value ? '#050624' : '#E5E7EB',
                            fontSize: '16px',
                            boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.20)',
                            '&:hover':
                              selectedTime === value ? { backgroundColor: '#F5F5F5' } : { backgroundColor: '#2A2D56' },
                          }}
                        >
                          {value}
                        </Button>
                      </Grid>
                    )
                  })}
                </Grid>
              )}
              {loadingContanier === false && !apiFailed && formattedTimes.length === 0 && (
                <div className={styles.noSlotDiv}>
                  <div>
                    <img src={'/images/noSlots.svg'} alt="no slots"></img>
                  </div>
                  <div className={styles.noSlots}>No slots available</div>
                  <div className={styles.dummySpan}>Please choose another date.</div>
                </div>
              )}
              {loadingContanier === false && apiFailed && formattedTimes.length === 0 && (
                <div className={styles.noSlotDiv}>
                  <div>
                    <img src={'/images/noSlots.svg'} alt="no slots"></img>
                  </div>
                  <div className={styles.noSlots}>Something went wrong</div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.btnWrapper}>
            <Grid item xs={12}>
              {slotBookedAlready && showDialogue && (
                <Dialog
                  onClose={() => {
                    // setShowDialogue(false)
                  }}
                  open={slotBookedAlready && showDialogue}
                  PaperProps={{ style: styless }}
                  className={styles.modalPopUp}
                >
                  <DialogTitle style={{ fontSize: '14px', marginTop: '0rem' }}>
                    Your payment has been processed successfully. Unfortunately, the slot you selected is no longer
                    available. Please choose another available slot.
                  </DialogTitle>
                  <Button
                    color="primary"
                    style={{
                      width: 'fit-content',
                      marginBottom: '1rem',
                      height: '2rem',
                      padding: '0px 8px 0px 8px',
                      fontSize: '12px',
                    }}
                    onClick={() => {
                      setShowDialogue(false)
                      dispatch(SetSlotBookedAlreadyBooking(false))
                    }}
                  >
                    Reschedule
                  </Button>
                </Dialog>
              )}
              {/* {error && (
                <Dialog
                  onClose={() => {
                    // setError(false)
                  }}
                  open={error}
                  PaperProps={{ style: styless }}
                  className={styles.modalPopUp}
                >
                  <DialogTitle style={{ fontSize: '14px', marginTop: '0rem' }}>
                    We’re sorry, the slot you picked has been booked already. Please select another slot.
                  </DialogTitle>
                  <Button
                    color="primary"
                    style={{
                      width: 'fit-content',
                      marginBottom: '1rem',
                      height: '2rem',
                      padding: '0px 8px 0px 8px',
                      fontSize: '12px',
                    }}
                    onClick={() => {
                      getDoctorAvailability(formattedDate)
                      setError(false)
                    }}
                  >
                    Reschedule
                  </Button>
                </Dialog>
              )} */}
            </Grid>
          </div>
        </div>
        <Footer
          buttonText={'Continue'}
          isLoading={loadingButton}
          trackerIndex={referralCode === null ? 2 : 1}
          // shouldShowFive={referralCode === null ? true : false}
          shouldShowFour={referralCode === null ? true : false}
          isDisabled={disableButton}
          buttonType={'button'}
          onButtonClick={() => {
            if (!disableButton && !loadingButton) {
              if (scheduleData) {
                dispatch(
                  SetSelectedSlotBooking({
                    scheduleObject: scheduleData,
                    index: selectedIndex,
                    value: selectedTime,
                    date: selectedDate,
                  }),
                )
                navigate(`/booking/${id}/confirm-visit`)
              }
            }
          }}
        ></Footer>
      </div>
    </>
  )
}

export default PhysicalVisitBooking
