import { useEffect, useMemo, useState } from 'react'
import { Button, Dialog, DialogTitle } from '@mui/material'
import axios from 'axios'
import { format } from 'date-fns'
import ReactGA from 'react-ga4'
import { useNavigate, useParams } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
import { SetPurchasedPlanBooking, SetTimeLeftToExpireBooking } from 'redux/booking'
import { useAppDispatch, useAppSelector } from 'redux/store'
import styles from './ConfirmAppointment.module.scss'

const ConfirmAppointmentBooking = () => {
  const styless: any = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //   position: 'absolute' as 'absolute',
    //   top: '44%',
    //   left: '47.5%',
    //   transform: 'translate(-50%, -50%)',
    width: 300,
    height: 170,
    overflow: 'hidden',
    backgroundColor: '#050624',
    color: 'white',
    borderRadius: '8px',
    p: 4,
  }
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const appointmentTime = useAppSelector((state) => state.booking.selectedSlotBooking)
  const paymentDone = useAppSelector((state) => state.booking.paymentDoneBooking)
  const plans = useAppSelector((state) => state.booking.availablePlansBooking)
  const visitCount = useAppSelector((state) => state.booking.visitCountBooking)
  const purchasedPlan = useAppSelector((state) => state.booking.purchasedPlanBooking)
  const selectedPlan = useAppSelector((state) => state.booking.selectedPlanBooking)

  const referralCode = useAppSelector((state) => state.booking.referralCodeBooking)
  const timeLeft: any = useAppSelector((state) => state.booking.timeLeftToExpireBooking)
  const [error, setError] = useState(false)

  const plan = useMemo(() => {
    if (plans) {
      const filterOnDemand = plans?.filter((p) => p.type === 'on-demand')
      return filterOnDemand
    } else {
      return null
    }
  }, [plans])

  console.log(plan, 'plan list')

  const schedulePhysicalVisit = async () => {
    if (appointmentTime?.scheduleObject) {
      const response: any = await axios
        .post(AppConstants.API_URL + '/visit/chiropractor/appointment', appointmentTime.scheduleObject, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })

      if (response?.data?.code === 'success') {
        // ReactGA.event('conversion', {
        //   send_to: process.env.REACT_APP_GA_CONVERSION_ID || 'AW-11337579902/40e2CN_xnqkZEP76lp4q',
        //   value: plan && plan[0] && plan[0]?.amount > 0 ? plan[0].amount / 100 : 0,
        //   currency: 'USD',
        // })
        navigate(`/booking/${id}/welcome`)
      } else {
        if (response?.data?.code === 'slot_booked_already') {
          setError(true)
          // dispatch(SetSlotBookedAlreadyReferralFlow(true))
        }
        return false
      }
    } else {
      return false
    }
  }

  async function purchasePlan(selectedPlanId: string | undefined) {
    try {
      const response: any = await axios
        .post(
          `${AppConstants.API_URL}/payment-plan/purchase`,
          { planId: selectedPlanId, quantity: 1 },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
              'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
            },
          },
        )
        .then((res) => {
          if (res && res?.data && res?.data?.data) {
            dispatch(SetPurchasedPlanBooking(res?.data?.data))
            navigate(`/booking/${id}/payment`)
          }
        })
        .catch((err) => {
          if (err) {
            alert('Something went wrong. Please try again.')
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'booking|Payment',
      page_location: `/booking/${id}/payment`,
    })
  }, [])

  useEffect(() => {
    dispatch(SetTimeLeftToExpireBooking(timeLeft ?? 299))
  }, [])

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        dispatch(SetTimeLeftToExpireBooking(timeLeft && timeLeft - 1))
      }, 1000)

      return () => clearInterval(timerId)
    }
  }, [timeLeft])

  return (
    <>
      <div className={styles.container}>
        <Header
          headerText="Confirm Visit"
          showBackButton={true}
          onBack={() => {
            navigate(`/booking/${id}/physical-visit`)
          }}
        ></Header>
        <div className={styles.locationContainer}>
          <div className={styles.appointmentTime}>
            {appointmentTime
              ? format(new Date(appointmentTime?.scheduleObject.appointmentTime), "EEE, MMM dd, yyyy 'at' h:mm a")
              : ''}
          </div>
          <div className={styles.locationDetails}>
            <div>
              <div className={styles.locationText}>KIRO Studio/1 in NoHo </div>
              <div className={styles.locationText}>302 Mott Street </div>
              <div className={styles.locationText}>New York, NY 10012</div>
            </div>
            <div className={styles.mapImage}>
              <img
                style={{ width: '93px', height: '73px', cursor: 'pointer' }}
                src={'/images/mapImage.png'}
                alt="map"
                onClick={() => {
                  window.open(
                    'https://www.google.com/maps/place/KIRO/@40.7248864,-73.996436,16z/data=!3m1!4b1!4m6!3m5!1s0x89c259f7e55d3a41:0x5a2269694f5fbb88!8m2!3d40.7248864!4d-73.9938611!16s%2Fg%2F11vyhmf57s?entry=ttu',
                    '_blank',
                  )
                  //   window.location.href =
                  //     'https://www.google.com/maps/place/302+Mott+St,+New+York,+NY+10012,+USA/@40.7248592,-73.9940347'
                }}
              />
            </div>
          </div>

          {error && (
            <Dialog
              onClose={() => {
                // setError(false)
              }}
              open={error}
              PaperProps={{ style: styless }}
              className={styles.modalPopUp}
            >
              <DialogTitle style={{ fontSize: '14px', marginTop: '0rem' }}>
                We’re sorry, the slot you picked has been booked already. Please select another slot.
              </DialogTitle>
              <Button
                color="primary"
                style={{
                  width: 'fit-content',
                  marginBottom: '1rem',
                  height: '2rem',
                  padding: '0px 8px 0px 8px',
                  fontSize: '12px',
                }}
                onClick={() => {
                  setError(false)
                  navigate(`/booking/${id}/physical-visit`)
                }}
              >
                Reschedule
              </Button>
            </Dialog>
          )}
        </div>
        {plan &&
        plan[0] &&
        plan[0]?.amount &&
        visitCount &&
        visitCount?.onDemandCount === 0 &&
        visitCount?.subscriptionCount === 0 &&
        referralCode ? (
          <div className={styles.offerDetails}>
            <span className={styles.offerDetailsHeader}>Offer Details</span>
            <div className={styles.offerText}>
              Due to limited availability for this promotion, we require a non-refundable payment of
              <b> ${plan[0]?.amount / 100}</b> to secure your spot. Please complete your payment below. We're looking
              forward to meeting you in our studio!
            </div>
          </div>
        ) : null}
        {visitCount && visitCount?.onDemandCount === 0 && visitCount?.subscriptionCount === 0 && referralCode ? (
          <div className={styles.offerExpires}>
            Offer Expires In: <span className={styles.timer}>{formatTime(timeLeft)}</span>
          </div>
        ) : null}
        <Footer
          buttonText={
            visitCount && (visitCount?.onDemandCount !== 0 || visitCount?.subscriptionCount !== 0)
              ? 'Confirm'
              : !paymentDone && visitCount?.onDemandCount === 0 && visitCount?.subscriptionCount === 0
              ? 'Confirm and Pay'
              : 'Confirm'
          }
          isLoading={false}
          trackerIndex={referralCode === null ? 3 : 2}
          // shouldShowFive={referralCode === null ? true : false}
          shouldShowFour={referralCode === null ? true : false}
          isDisabled={false}
          buttonType={'button'}
          onButtonClick={() => {
            if (
              visitCount &&
              (visitCount?.onDemandCount !== 0 || visitCount?.subscriptionCount !== 0) &&
              appointmentTime?.scheduleObject
            ) {
              schedulePhysicalVisit()
            } else if (
              plan &&
              plan[0]?.id &&
              visitCount &&
              visitCount?.onDemandCount === 0 &&
              visitCount?.subscriptionCount === 0 &&
              appointmentTime?.scheduleObject
            ) {
              if (!paymentDone) {
                if (!referralCode) {
                  purchasePlan(selectedPlan?.id)
                } else {
                  purchasePlan(plan[0]?.id)
                }
              }
            }
          }}
        ></Footer>
      </div>
    </>
  )
}

export default ConfirmAppointmentBooking
