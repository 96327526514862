import React, { useEffect, useMemo, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import Header from 'components/Header'
import { CheckoutForm } from './CheckoutForm'
import styles from './Payment.module.scss'

const PaymentBland = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [purchaseInfo, setPurchaseInfo] = useState<any>({
    clientSecret: null,
    paymentId: null,
    paymentIntentId: null,
  })

  //cardPayment
  const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY || ''
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

  const options: StripeElementsOptions = {
    clientSecret: purchaseInfo?.clientSecret,
    appearance: {
      theme: 'night',
      variables: {
        colorBackground: '#050624',
      },
    },
  }

  async function blandPayment() {
    try {
      const response: any = await axios
        .get(`${AppConstants.API_URL}/payment-plan/bland/payment/${id}`, {
          headers: {
            // Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        })
        .then((res) => {
          if (res && res?.data && res?.data?.data) {
            setPurchaseInfo({
              ...purchaseInfo,
              clientSecret: res?.data?.data?.clientSecret,
              paymentId: res?.data?.data?.paymentId,
              paymentIntentId: res?.data?.paymentIntentId,
            })
            setLoading(false)
          }
        })
        .catch((err) => {
          if (err) {
            setError(true)
            setLoading(false)
            alert('Something went wrong. Please try again later.')
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    blandPayment()
  }, [])

  if (loading) {
    return (
      <div className={styles.container}>
        <div className={styles.circularProgress}>
          <CircularProgress
            size={40}
            sx={{
              color: '#007F7C',
              width: '14px',
              height: '14px',
            }}
          />
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className={styles.container}>
        <div className={styles.error}></div>
      </div>
    )
  }

  return (
    <div>
      <div className={styles.paymentContainer}>
        <Header headerText="Add Payment Details" showBackButton={false}></Header>
        {options?.clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <div className={styles.paymentContainerDetails}>
              <CheckoutForm clientSecret={purchaseInfo?.clientSecret} planId={purchaseInfo?.paymentId}></CheckoutForm>
            </div>
          </Elements>
        )}
      </div>
    </div>
  )
}

export default PaymentBland
