import { Button, CircularProgress } from '@mui/material'
import styles from './Footer.module.scss'
//import { default as Logo } from '../images/kiro-logo.svg'

interface FooterProps {
  buttonId?: string
  buttonText: string
  buttonType?: any
  onButtonClick?: () => void | undefined
  isLoading: boolean
  isDisabled: boolean
  shouldShowFour?: boolean
  shouldShowSix?: boolean
  shouldShowFive?: boolean
  trackerIndex: number
}

export default function Footer({
  buttonText,
  buttonId,
  buttonType,
  onButtonClick,
  isDisabled,
  isLoading,
  trackerIndex,
  shouldShowFour,
  shouldShowFive,
  shouldShowSix,
}: FooterProps) {
  return (
    <div className={styles.footer}>
      {trackerIndex !== 0 && (
        <div className={trackerIndex === 10 ? styles.disableTracker : styles.trackerContainer}>
          <div className={trackerIndex >= 1 ? styles.activeStep : styles.inactiveStep}></div>
          <div className={trackerIndex >= 2 ? styles.activeStep : styles.inactiveStep}></div>
          <div className={trackerIndex >= 3 ? styles.activeStep : styles.inactiveStep}></div>
          {/* <div className={trackerIndex >= 4 ? styles.activeStep : styles.inactiveStep}></div> */}
          {shouldShowFour && <div className={trackerIndex >= 4 ? styles.activeStep : styles.inactiveStep}></div>}
          {shouldShowFive && <div className={trackerIndex >= 5 ? styles.activeStep : styles.inactiveStep}></div>}
          {shouldShowSix && <div className={trackerIndex >= 6 ? styles.activeStep : styles.inactiveStep}></div>}
          {/* <div className={trackerIndex >= 7 ? styles.activeStep : styles.inactiveStep}></div> */}
        </div>
      )}
      <Button
        id={buttonId ? buttonId : ''}
        className={styles.button}
        sx={isDisabled ? { opacity: '0.5' } : {}}
        type={buttonType ? buttonType : 'button'}
        onClick={onButtonClick}
        style={{ width: '97%' }}
      >
        {!isLoading && buttonText}
        {isLoading && (
          <CircularProgress
            size={20}
            sx={{
              color: 'white',
              width: '14px',
              height: '14px',
              left: '90%',
            }}
          />
        )}
      </Button>
      {/* <div className={styles.botton}>{buttonText}</div> */}
    </div>
  )
}
