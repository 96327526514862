import { createSlice } from '@reduxjs/toolkit'

export interface BookingState {
  skipCongratsBooking: boolean
}

const initialState: BookingState = {
  skipCongratsBooking: false,
}

export const BookingSlice = createSlice({
  name: 'BookingNonPersist',
  initialState,
  reducers: {
    SetSkipCongratsBooking: (state, action) => {
      state.skipCongratsBooking = action.payload
    },
  },
})

export default BookingSlice.reducer

export const { SetSkipCongratsBooking } = BookingSlice.actions
