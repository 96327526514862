import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import {
  SetAvailablePlansBooking,
  SetPaymentDoneBooking,
  SetPurchasedPlanBooking,
  SetReferralCodeBooking,
  SetSelectedPlanBooking,
  SetSelectedSlotBooking,
  SetSlotBookedAlreadyBooking,
  SetTimeLeftToExpireBooking,
  SetVisitCountBooking,
} from 'redux/booking'
import { SetSkipCongratsBooking } from 'redux/booking/nonPersist'
import { useAppDispatch } from 'redux/store'
import { StatusEnum } from 'redux/types'
import styles from './Booking.module.scss'

export default function Booking() {
  const [fetchTokenStatus, setFetchTokenStatus] = useState(StatusEnum.Idle)
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  //
  async function fetchToken() {
    setFetchTokenStatus(StatusEnum.Pending)
    const response: any = await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/auth/generate-token/${id}`, {
        headers: {
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      .then((res) => res)
      .catch((err) => err.response)

    if (response && response.data && response.data.code === 'success') {
      localStorage.setItem(AppConstants.ACCESS_TOKEN, response?.data?.data?.accessToken)
      let localToken = response.data?.data?.accessToken
      let tokenParts = localToken.split('.')
      let tokenPayload = tokenParts[1]
      let tokenPayloadStr = atob(tokenPayload)
      let decodedToken: any = JSON.parse(tokenPayloadStr)
      let tempCurrentRole: any = ''
      if (decodedToken) {
        if (decodedToken.roles && decodedToken.roles.length === 1) {
          tempCurrentRole = decodedToken.roles[0]
        }
      } else {
        tempCurrentRole = null
      }
      localStorage.setItem(AppConstants.CURRENTROLE_TOKEN, tempCurrentRole)
      setFetchTokenStatus(StatusEnum.Success)
    } else if (response && response?.data && response.data.code === 'user_not_found') {
      setFetchTokenStatus(StatusEnum.Failed)
      alert('User not found')
      navigate(`/`)
    }
  }

  async function getPlans(campaignCode: string) {
    try {
      const response: any = await axios.get(`${AppConstants.API_URL}/payment-plan?campaign=${campaignCode}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      if (response && response?.data && response?.data?.data) {
        console.log(response?.data?.data, 'plans')
        dispatch(SetAvailablePlansBooking(response?.data?.data))
      } else if (response?.data?.statusCode === 401) {
        console.log('error')
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function getProfile() {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })

      dispatch(
        SetVisitCountBooking({
          onDemandCount: response?.data?.data?.onDemandCount ?? null,
          subscriptionCount: response?.data?.data?.subscriptionCount ?? null,
          previousVisits: response?.data?.data?.previousVisits ?? null,
        }),
      )
      dispatch(SetReferralCodeBooking(response?.data?.data?.referralCode))
      dispatch(SetPaymentDoneBooking(response?.data?.data?.initialPayment))

      if (response && response?.data.code === 'success' && response?.data?.data?.referralCode !== null) {
        getPlans(response?.data?.data?.referralCode)
      }
      // if (response && response.data && response.data.data) {
      //   if (response.data.data.previousVisits === true && response?.data?.data?.initialPayment === true) {
      //     if (!response.data.data.lastname || !response.data.data.email || !response.data.data.dob) {
      //       dispatch(SetSkipCongratsBooking(true))
      //       navigate(`/booking/${id}/signup`)
      //       return
      //     }
      //   }
      // }

      if (
        response &&
        response?.data.code === 'success' &&
        response?.data?.data?.referralCode === null &&
        response?.data?.data?.initialPayment === false &&
        response?.data?.data?.previousVisits === false
      ) {
        navigate(`/booking/${id}/choose-plan`)
      } else if (
        response &&
        response?.data.code === 'success' &&
        response?.data?.data?.referralCode === null &&
        response?.data?.data?.initialPayment === true &&
        response?.data?.data?.previousVisits === false
      ) {
        navigate(`/booking/${id}/physical-visit`)
      } else if (
        response &&
        response?.data.code === 'success' &&
        response?.data?.data?.referralCode !== null &&
        response?.data?.data?.previousVisits === false
      ) {
        navigate(`/booking/${id}/physical-visit`)
      } else {
        navigate(`/booking/${id}/download`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    dispatch(SetSelectedPlanBooking(null))
    dispatch(SetSelectedSlotBooking(null))
    dispatch(SetAvailablePlansBooking(null))
    dispatch(SetSlotBookedAlreadyBooking(false))
    dispatch(SetPurchasedPlanBooking(null))
    dispatch(SetVisitCountBooking(null))
    dispatch(SetPaymentDoneBooking(false))
    dispatch(SetReferralCodeBooking(null))
    dispatch(SetTimeLeftToExpireBooking(null))

    if (fetchTokenStatus === StatusEnum.Idle) {
      fetchToken()
    }
    if (fetchTokenStatus === StatusEnum.Success) {
      getProfile()
    }
  }, [fetchTokenStatus])

  return (
    <div className={styles.container}>
      {fetchTokenStatus === StatusEnum.Pending && (
        <div className={styles.circularProgress}>
          <CircularProgress
            size={40}
            sx={{
              color: '#007F7C',
              width: '14px',
              height: '14px',
            }}
          />
        </div>
      )}
    </div>
  )
}
