import { createSlice } from '@reduxjs/toolkit'

export interface RegularState {
  firstname: string | null
  skipCongrats: boolean
}

const initialState: RegularState = {
  firstname: null,
  skipCongrats: false,
}

export const RegularSlice = createSlice({
  name: 'regular',
  initialState,
  reducers: {
    SetRegularFirstname: (state, action) => {
      state.firstname = action.payload
    },
    SetSkipCongrats: (state, action) => {
      state.skipCongrats = action.payload
    },
  },
})

export default RegularSlice.reducer

export const { SetRegularFirstname, SetSkipCongrats } = RegularSlice.actions
